import { defineStore } from "pinia";

export const themeModule = defineStore('theme',{
  state: () => ({
    themeObj: {
      theme: "light",
      nav: "vertical",
      layout: {
        mode: "fluid",
        position: "fixed"
      },
      topbar: {
        color: "light"
      },
      menu: {
        color: "dark"
      },
      sidenav: {
        size: "default", // default, condensed, full
        user: false
      }
    }
  }),

  actions: {
    setTheme(payload) {
      this.themeObj.theme = payload;
      this.setHtmlAtributes()
      this.setLocalStorage()
    },
    setNav(payload) {
      this.themeObj.nav = payload;
      this.setHtmlAtributes()
    },
    setLayout(payload) {
      this.themeObj.layout = payload;
      this.setHtmlAtributes()
    },
    setTopbar(payload) {
      this.themeObj.topbar = payload;
      this.setHtmlAtributes()
    },
    setMenu(payload) {
      this.themeObj.menu = payload;
      this.setHtmlAtributes()
    },
    setSidenav(payload) {
      this.themeObj.sidenav.size = payload;
      this.setHtmlAtributes()
    },
    setLocalStorage() {
      localStorage.setItem('__DMD_CONFIG__', JSON.stringify(this.themeObj));
      this.setHtmlAtributes()
    },
    getLocalStorage() {
      if (localStorage.getItem('__DMD_CONFIG__')) {
        this.themeObj = JSON.parse(localStorage.getItem('__DMD_CONFIG__'));
      }
    },
    setHtmlAtributes() {
      let container = document.getElementsByTagName('html')[0];

      container.setAttribute('data-bs-theme', this.themeObj.theme);
      container.setAttribute('data-menu-color', this.themeObj.menu.color);
      container.setAttribute("data-layout-mode", this.themeObj.layout.mode);
      container.setAttribute("data-menu-color", this.themeObj.menu.color);
      container.setAttribute("data-topbar-color", this.themeObj.topbar.color);
      container.setAttribute("data-layout-position", this.themeObj.layout.position);
      container.setAttribute("data-sidenav-size", this.themeObj.sidenav.size);
      // container.setAttribute("data-sidenav-user", "true"); TODO BUILD OUT THEME CONTROLS
    }
  }

})