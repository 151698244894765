<script setup>
  import { RouterView } from 'vue-router'
  import layouts from "./layouts.js";
  import { shallowRef, provide, onMounted, nextTick } from "vue";
  import router from "./router/index.js";
  import { themeModule } from "./stores/theme.js";

  const themeStore = themeModule()
  autoSize(window.innerWidth)

  onMounted(() => {
    themeStore.setLocalStorage()
    checkSystemTheme()

    window.onresize = () => {
      autoSize(window.innerWidth)
    }
  })

  const layout = shallowRef('div')
  router.afterEach((to) => {
    layout.value = layouts[to.meta.layout] || 'div'
  })

  provide('app:layout', layout)

  const checkSystemTheme = () => {
    let theme = window.matchMedia('(prefers-color-scheme: dark)')
    theme.matches ? themeStore.setTheme("dark") : themeStore.setTheme("light")

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const newColorScheme = event.matches ? "dark" : "light";
      themeStore.setTheme(newColorScheme)
    });
  }

  function autoSize(s) {
    let container = document.getElementsByTagName('html')[0];

    if (themeStore.themeObj.nav === "vertical") {
      let size = container.getAttribute('data-sidenav-size')
      nextTick(() => {
        let size = themeStore.themeObj.sidenav.size;
        if (s <= 767) {
          size = "full";
        } else if (s >= 767 && s <= 1140) {
          if (themeStore.themeObj.sidenav.size !== 'full' && themeStore.themeObj.sidenav.size !== 'fullscreen') {
            size = "condensed";
          }
        }
        themeStore.setSidenav(size)
        // if (themeStore.themeObj.sidenav.user && themeStore.themeObj.sidenav.user.toString() === "true") {
        //   html.setAttribute("data-sidenav-user", "true");
        // } else {
        //   html.removeAttribute("data-sidenav-user");
        // }
      })
    }
  }
</script>

<template>
  <component :is="layout || 'div'">
    <RouterView />
  </component>
</template>

<style scoped>

</style>
