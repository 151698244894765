import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { axiosPlugin } from './plugins/axios'
import axios from 'axios'
import { createPinia } from 'pinia'
import "bootstrap"
import "@popperjs/core"
import '@/assets/js/vendor.min.js'
import '@/assets/scss/app-saas.scss'
import '@/assets/scss/icons.scss'
import Swal from "sweetalert2";
import ErrorComponent from './layouts/error.vue'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import VueApexCharts from "vue3-apexcharts";
import { authModule } from "@/stores/auth.js";
import tooltip from "@/directives/tooltip.js";
import popover from "@/directives/popover.js";
import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
  app,
  environment: process.env.NODE_ENV,
  dsn: "https://1ccf09205fc24b33bf75c85f27c3f158@o4504166536839168.ingest.us.sentry.io/4504166549291008",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/dmdlogisticsadmin\.net\/admin/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Directives
app.directive('phone', {
  created(el) {
    el.addEventListener('input', function(event) {
      let phone = event.target.value.replace(/\D/g, '').substring(0, 10);
      event.target.value = phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    });
  }
})
app.directive('trn', {
  created(el) {
    el.addEventListener('input', function(event) {
      let trn = event.target.value.replace(/\D/g, '').substring(0, 9);
      event.target.value = trn.replace(/(\d{3})(\d{3})(\d{3})/, '$3-$3-$3');
    });
  }
})
app.directive('tooltip', tooltip)
app.directive('popover', popover)

app.config.errorHandler = (err, vm, info) => {
  console.error(`Error: `, err);
  app.mount('#app', {
    render: () => h(ErrorComponent, { props: { errorMessage: err.message }})
  });
}

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? "https://dmdlogisticsadmin.net" : "http://localhost:1002",
  withCredentials: false
})

axiosInstance.interceptors.request.use(function (config) {
  config.headers.Authorization =  'Bearer ' + localStorage.getItem('token');
  return config;
});

app.config.globalProperties.$swal = Swal

const pinia = createPinia()
pinia.use(({ store }) => {
  store.$axios = axiosInstance
  store.$router = router
})

// Adds instances to set up
app.provide('swal', Swal);

app.component('multiselect', VueMultiselect)
app.use(VueApexCharts);
app.use(pinia)
app.use(router)
app.use(axiosPlugin)

const authStore = authModule()
authStore.persistUser()

app.mount('#app')