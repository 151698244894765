<script>
import { defineAsyncComponent } from "vue";
import { generalModule } from "@/stores/general.js";
import { mapStores } from 'pinia'
const dropMatch = defineAsyncComponent(() => import("@/components/modals/dropMatchBatch.vue"))
import { Modal } from "bootstrap";

export default {
  name: "leftSideMenu",
  components: { dropMatch },
  data() {
    return {
      dropMatchModal: {}
    }
  },
  mounted() {
    this.$axios.post('/admin/package/unported-notification').then(response => {
      this.generalStore.setUnPortedCount(response.data.total)
    })
  },
  computed: {
    ...mapStores(generalModule)
  },
  methods: {
    batch() {
      this.dropMatchModal = new Modal(document.getElementById('dropMatchBatch'))
      this.dropMatchModal.show()
    }
  }
}
</script>

<template>
  <drop-match :modal="dropMatchModal" />
  <div class="leftside-menu">

    <!-- Brand Logo Light -->
    <router-link to="/" class="logo logo-light">
      <span class="logo-lg">
          <img src="/DMD_light.png" alt="logo">
      </span>
      <span class="logo-sm">
        <img src="/DMD_light.png" alt="small logo">
      </span>
    </router-link>

    <!-- Brand Logo Dark -->
    <router-link to="/" class="logo logo-dark">
      <span class="logo-lg">
          <img src="/DMD_dark.png" alt="dark logo">
      </span>
      <span class="logo-sm">
        <img src="/DMD_dark.png" alt="small logo">
      </span>
    </router-link>

    <!-- Sidebar Hover Menu Toggle Button -->
    <div class="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
      <i class="ri-checkbox-blank-circle-line align-middle"></i>
    </div>

    <!-- Full Sidebar Menu Close Button -->
    <div class="button-close-fullsidebar">
      <i class="ri-close-fill align-middle"></i>
    </div>

    <!-- Sidebar -left -->
    <div class="h-100" id="leftside-menu-container" data-simplebar>
      <!-- Leftbar User -->
      <div class="leftbar-user">
        <a href="pages-profile.html">
<!--          <img src="../assets/images/users/avatar-1.jpg" alt="user-image" height="42" class="rounded-circle shadow-sm">-->
          <span class="leftbar-user-name mt-2">Dominic Keller</span>
        </a>
      </div>

      <!--- Sidemenu -->
      <ul class="side-nav">

        <li class="side-nav-title">Navigation</li>

        <li class="side-nav-item">
          <router-link to="/" class="side-nav-link">
            <i class="uil-home-alt"></i>
            <span> Dashboard </span>
          </router-link>
        </li>

        <li class="side-nav-item">
          <a data-bs-toggle="collapse" href="#sidebarCompanies" aria-expanded="false" aria-controls="sidebarCompanies" class="side-nav-link">
            <i class="uil-building"></i>
            <span> Companies </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarCompanies">
            <ul class="side-nav-second-level">
              <li>
                <router-link to='/company'>List</router-link>
              </li>
              <li>
                <router-link to='/company/create'>Create</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="side-nav-item">
          <a data-bs-toggle="collapse" href="#sidebarPackages" aria-expanded="false" aria-controls="sidebarPages" class="side-nav-link">
            <i class="uil-package"></i>
            <span> Packages </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarPackages">
            <ul class="side-nav-second-level">
              <li>
                <router-link to='/package'>List</router-link>
              </li>
              <li>
                <router-link to='/package/create'>Create</router-link>
              </li>
              <li>
                <a href='javascript:void(0);' @click="batch">Batch</a>
              </li>
              <li>
                <router-link to='/package/drop'>Drop</router-link>
              </li>
              <li>
                <router-link to='/package/requests'>
                  <span class="badge bg-success float-end" v-if="generalStore.pendingRequests > 0">{{ generalStore.pendingRequests }}</span>
                  <span>Unknown Requests</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="side-nav-item">
          <a data-bs-toggle="collapse" href="#sidebarBags" aria-expanded="false" aria-controls="sidebarBags" class="side-nav-link">
            <i class="uil-bag"></i>
            <span> Bags </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarBags">
            <ul class="side-nav-second-level">
              <li>
                <router-link to='/bag'>List</router-link>
              </li>
              <li>
                <router-link to='/bag/create'>Create</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="side-nav-item">
          <a data-bs-toggle="collapse" href="#sidebarShipments" aria-expanded="false" aria-controls="sidebarShipments" class="side-nav-link">
            <i class="uil-plane-departure"></i>
            <span> Shipments </span>
            <span class="menu-arrow"></span>
          </a>
          <div class="collapse" id="sidebarShipments">
            <ul class="side-nav-second-level">
              <li>
                <router-link to='/shipment'>List</router-link>
              </li>
              <li>
                <router-link to='/shipment/create'>Create</router-link>
              </li>
              <li>
                <router-link to='/shipment/asycuda-xml'>Generate XML</router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="side-nav-title">Admin Pages</li>

        <li class="side-nav-item">
          <router-link to="/commander/settings" class="side-nav-link">
            <i class="uil-cog"></i>
            <span> Settings </span>
          </router-link>
        </li>
        <li class="side-nav-item">
          <router-link to="/commander/clients" class="side-nav-link">
            <i class="uil-user"></i>
            <span> Clients </span>
          </router-link>
        </li>
        <li class="side-nav-item">
          <router-link to="/commander/unported" class="side-nav-link">
            <i class="uil-sync-exclamation"></i>
            <span class="badge bg-warning float-end" v-if="generalStore.unPorted > 0">{{ generalStore.unPorted }}</span>
            <span> Unported Packages </span>
          </router-link>
        </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<style scoped>

</style>