import { defineStore } from "pinia";

export const authModule = defineStore('auth',{
  state: () => ({
    user: null
  }),
  actions: {
    setUser(payload) {
      this.user = payload;
      localStorage.setItem('usr', JSON.stringify(payload));
    },
    async persistUser() {
      let usr = localStorage.getItem('usr');
      if (usr) {
        this.setUser(JSON.parse(usr));
      }
    },
    async fetchProfile() {
      try {
        let { data } = await this.$axios.get('/admin/profile')
        this.setUser(data);
      } catch (e) {
        console.log(e);
      }
    },
    logOut(state) {
      state.user = null;
      localStorage.removeItem("token");
      this.$router.push('/login')
      localStorage.removeItem("usr");
    }
  },
  getters: {
    isLoggedIn(state)  {
      return !!state.user;
    },
    userObj: (state) => {
      return state.user;
    }
  }
})