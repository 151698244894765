import { createRouter, createWebHistory } from 'vue-router'
import { authModule } from "@/stores/auth.js";
import * as jose from 'jose'
const Dashboard = () => import('../views/index.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/login.vue'),
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('token')) {
          let token = localStorage.getItem('token')
          let decoded = jose.decodeJwt(token)
          if (decoded.exp < Date.now() / 1000) {
            next({ path: '/login' })
          } else {
            next({ path: '/' })
          }
        } else {
          next()
        }
      }
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: Dashboard,
    },
    {
      path: '/company/',
      name: 'Company List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/company/index.vue')
    },
    {
      path: '/company/create',
      name: 'Create Company',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/company/create.vue')
    },
    {
      path: '/company/:id',
      name: 'Edit Company',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/company/id.vue')
    },
    {
      path: '/package',
      name: 'Package List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/index.vue')
    },
    {
      path: '/package/requests',
      name: 'Unknown Requests',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/requests/index.vue')
    },
    {
      path: '/package/requests/:id',
      name: 'Edit Request',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/requests/id.vue')
    },
    {
      path: '/package/create',
      name: 'Create Package',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/create.vue')
    },
    {
      path: '/package/drop',
      name: 'Package Drop',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/drop.vue')
    },
    {
      path: '/package/:id',
      name: 'Edit Package',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/package/id.vue')
    },
    {
      path: '/bag',
      name: 'Bag List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/bag/index.vue')
    },
    {
      path: '/bag/create',
      name: 'Create Bag',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/bag/create.vue')
    },
    {
      path: '/bag/:id',
      name: 'Edit Bag',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/bag/id.vue')
    },
    {
      path: '/shipment',
      name: 'Shipment List',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/shipment/index.vue')
    },
    {
      path: '/shipment/create',
      name: 'Create Shipment',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/shipment/create.vue')
    },
    {
      path: '/shipment/:id',
      name: 'Edit Shipment',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/shipment/id.vue')
    },
    {
      path: '/shipment/asycuda-xml',
      name: 'Asycuda XML',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/shipment/asycuda-xml.vue')
    },

    {
      path: '/account',
      name: 'My Account',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/account.vue')
    },
    {
      path: '/commander/settings',
      name: 'Settings',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/commander/settings.vue')
    },
    {
      path: '/commander/clients',
      name: 'Clients',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/commander/clients.vue')
    },
    {
      path: '/commander/unported',
      name: 'Unported Packages',
      meta: { layout: 'homeLayout', requiresAuth: true },
      component: () => import('../views/commander/ported.vue')
    },

    {
      path: '/error/500',
      name: 'Error',
      meta: { layout: 'authLayout', requiresAuth: false },
      component: () => import('../views/error/500.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error',
      meta: { layout: 'errorLayout', requiresAuth: false },
      component: () => import('../views/error/404.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = authModule()

  let tips = document.querySelectorAll('.tooltip')
  let pops = document.querySelectorAll('.popover')
  if (tips.length > 0) {
    tips.forEach((tooltip) => {
      tooltip.remove()
    });
  }
  if (pops.length > 0) {
    pops.forEach((popover) => {
      popover.remove()
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({ path: '/login' })
    } else if (authStore.isLoggedIn && authStore.userObj.account_type !== 'admin' || authStore.userObj.account_type !== 'hero') {
      if (authStore.userObj.account_type === 'staff' && authStore.userObj.permissions.length === 0) {
        console.log('Misconfigured Account')
      } else {
        if ( authStore.userObj.permissions.some(p => p.split('_')[0] === 'HIDE' && to.name.toLowerCase().includes(p.split('_')[1].toLowerCase())) ) {
          next({ path: from.fullPath })
        } else {
          next()
        }
      }
    } else {
      let token = localStorage.getItem('token')
      let decoded = jose.decodeJwt(token)

      if (decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('token')
        next({ path: '/login' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
