import { defineStore } from "pinia";

export const generalModule = defineStore('general',{
  state: () => ({
    pendingRequests: 0,
    unPorted: 0
  }),
  actions: {
    setRequestCount(payload) {
      this.pendingRequests = payload;
    },
    setUnPortedCount(payload) {
      this.unPorted = payload;
    }
  },
  getters: {
    requestCount: (state) => {
      return state.pendingRequests;
    }
  }
})