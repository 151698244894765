<script setup>
  import { formatDistanceToNow } from 'date-fns';
  import { authModule } from "../stores/auth.js";
  import { themeModule } from "../stores/theme.js";
  import { inject, onMounted, ref } from "vue";

  let notifFlow = ref(null)
  let token = ref(localStorage.getItem('token'))
  let notofications = ref([])

  const axios = inject('axios')
  onMounted(() => {
    if (!notifFlow.value && token.value) {
      notifFlow.value = new EventSource(`${axios.defaults.baseURL}/admin/notification/?token=${token.value}`, { withCredentials: false });

      notifFlow.value.onerror = (error) => {
        // console.log(`notiFlow.error`, error);
        notifFlow.value.close()
      }
      notifFlow.value.onopen = (args) => {
        // console.log(`notifFlow.open`, args);
      }
      notifFlow.value.onmessage = (event) => {
        // console.log(`eventBandwidth.event`, event);
        try {
          notofications.value = JSON.parse(event.data);
        } catch (e) {
          console.log(`notiFlow.catch: ${e.message}`);
          throw new Error(e)
        }
      }
    }
  })

  const store = authModule()
  const themeStore = themeModule()

  function timeAgo(date) {
    return formatDistanceToNow(date)
  }
  function lightDark() {
    let payload = document.getElementsByTagName('html')[0]
    let theme = payload.getAttribute('data-bs-theme')
    themeStore.setTheme(theme === 'dark' ? 'light' : 'dark')
  }
  function fullScreen() {
    let payload = document.getElementsByTagName('body')[0]
    payload.className = payload.className === 'show fullscreen-enable' ? '' : 'show fullscreen-enable'
  }
  function sideBar() {
    let payload = document.getElementsByTagName('html')[0]
    let size = payload.getAttribute('data-sidenav-size')
    themeStore.setSidenav(size === 'default' ? 'condensed' : 'default')
  }
  function initials(fullName) {
    if (fullName) {
      const allNames = fullName.trim().split(' ');
      return allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');
    }
  }
  function fullScreenListener() {
    document.body.classList.toggle("fullscreen-enable");
    if (!document.fullscreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      if (document["exitFullscreen"]) {
        document["exitFullscreen"]();
      }
    }
  }
  function read(id) {
    axios.put(`/admin/notification/${id}`)
  }
  function readAll() {
    axios.post(`/admin/notification/read-all`)
  }
</script>

<template>
  <div class="navbar-custom">
    <div class="topbar container-fluid">
      <div class="d-flex align-items-center gap-lg-2 gap-1">

        <!-- Topbar Brand Logo -->
        <div class="logo-topbar">
          <!-- Logo light -->
          <a href="/" class="logo-light">
            <span class="logo-lg">
                <img src="/DMD_light.png" alt="logo">
            </span>
            <span class="logo-sm">
                <img src="/DMD_light.png" alt="small logo">
            </span>
          </a>

          <!-- Logo Dark -->
          <a href="/" class="logo-dark">
            <span class="logo-lg">
                <img src="/DMD_dark.png" alt="dark logo">
            </span>
            <span class="logo-sm">
                <img src="/DMD_dark.png" alt="small logo">
            </span>
          </a>
        </div>

        <!-- Sidebar Menu Toggle Button -->
        <button class="button-toggle-menu" @click="sideBar">
          <i class="mdi mdi-menu"></i>
        </button>

        <!-- Horizontal Menu Toggle Button -->
        <button class="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>

        <!-- Topbar Search Form -->
        <div class="app-search dropdown d-none d-lg-block">
          <form>
            <div class="input-group">
              <input type="search" class="form-control dropdown-toggle" placeholder="Search..." id="top-search">
              <span class="mdi mdi-magnify search-icon"></span>
              <button class="input-group-text btn btn-primary" type="submit">Search</button>
            </div>
          </form>

          <div class="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
            <!-- item-->
            <div class="dropdown-header noti-title">
              <h5 class="text-overflow mb-2">Found <span class="text-danger">17</span> results</h5>
            </div>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-notes font-16 me-1"></i>
              <span>Analytics Report</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-life-ring font-16 me-1"></i>
              <span>How can I help you?</span>
            </a>

            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item notify-item">
              <i class="uil-cog font-16 me-1"></i>
              <span>User profile settings</span>
            </a>

            <!-- item-->
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
            </div>

            <div class="notification-list">
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <div class="d-flex">
<!--                  <img class="d-flex me-2 rounded-circle" src="../assets/images/users/avatar-2.jpg" alt="Generic placeholder image" height="32">-->
                  <div class="w-100">
                    <h5 class="m-0 font-14">Erwin Brown</h5>
                    <span class="font-12 mb-0">UI Designer</span>
                  </div>
                </div>
              </a>

              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item">
                <div class="d-flex">
<!--                  <img class="d-flex me-2 rounded-circle" src="../assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height="32">-->
                  <div class="w-100">
                    <h5 class="m-0 font-14">Jacob Deo</h5>
                    <span class="font-12 mb-0">Developer</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <ul class="topbar-menu d-flex align-items-center gap-3">
        <li class="dropdown d-lg-none">
          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <i class="ri-search-line font-22"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
            <form class="p-3">
              <input type="search" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
            </form>
          </div>
        </li>

<!--        Notifications-->
        <li class="dropdown notification-list">
          <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <i class="ri-notification-3-line font-22"></i>
            <span class="noti-icon-badge" v-if="notofications.length > 0"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg py-0">
            <div class="p-2 border-top-0 border-start-0 border-end-0 border-dashed border">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0 font-16 fw-semibold"> Notification</h6>
                </div>
                <div class="col-auto" v-if="notofications.length > 0">
                  <a href="javascript: void(0);" @click="readAll" class="text-dark text-decoration-underline">
                    <small>Clear All</small>
                  </a>
                </div>
              </div>
            </div>

            <div class="px-2" style="max-height: 300px;" data-simplebar>
              <p class="mt-3"></p>
              <a href="javascript:void(0);" class="dropdown-item p-0 notify-item card unread-noti shadow-none mb-2" v-if="notofications.length > 0" v-for="n in notofications" :key="n._id">
                <div class="card-body">
                  <span class="float-end noti-close-btn text-muted" @click="read(n._id)"><i class="mdi mdi-close"></i></span>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <div class="notify-icon bg-primary">
                        <i class="mdi mdi-comment-account-outline"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 text-truncate ms-2">
                      <h5 class="noti-item-title fw-semibold font-14">{{ n.title }} <small class="fw-normal text-muted ms-1">{{ timeAgo(n.created_ts) }}</small></h5>
                      <small class="noti-item-subtitle text-muted">{{ n.message }}</small>
                    </div>
                  </div>
                </div>
              </a>
              <div v-else class="text-center">
                <p>No Notifications</p>
              </div>
            </div>

            <!-- All-->
<!--            <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item border-top py-2">-->
<!--              View All-->
<!--            </a>-->

          </div>
        </li>

        <li class="d-none d-sm-inline-block">
          <div class="nav-link" id="light-dark-mode" @click="lightDark" v-tooltip:left="'Theme Mode'">
            <i class="ri-moon-line font-22"></i>
          </div>
        </li>

        <li class="d-none d-md-inline-block">
          <a class="nav-link" href="javascript:void(0);" @click="fullScreenListener" v-tooltip:bottom="'Toggle Fullscreen'" data-toggle="fullscreen">
            <i class="ri-fullscreen-line font-22"></i>
          </a>
        </li>

        <li class="dropdown">
          <a class="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
            <div class="avatar-sm" v-if="store.isLoggedIn">
              <span class="avatar-title bg-info rounded-circle">{{ initials(store.userObj.name) }}</span>
            </div>
            <span class="d-lg-flex flex-column gap-1 d-none">
                <h5 class="my-0" v-if="store.isLoggedIn">{{ store.userObj.name }}</h5>
                <h6 class="my-0 fw-normal" v-if="store.isLoggedIn">{{ store.userObj.account_type.toUpperCase() }}</h6>
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">

            <!-- item-->
            <router-link to="/account" class="dropdown-item">
              <i class="mdi mdi-account-circle me-1"></i>
              <span>My Account</span>
            </router-link>

            <!-- item-->
            <a href="javascript:void(0);" @click="store.logOut" class="dropdown-item">
              <i class="mdi mdi-logout me-1"></i>
              <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>